import { createAction } from '@reduxjs/toolkit'
import { ItemSummary, ListHistory, ListSummary } from "./type"

// for loading page 
export const getLoading = createAction<{isLoading:boolean}>('history/getLoading')

// for data history
export const getDataListHisroty = createAction<ListHistory>('history/getDataListHisroty')

// for data summary
export const getDataListSummary = createAction<ItemSummary>('history/getDataListSummary')