import styled from "styled-components";
import { Flex } from "../Box";

const StyledBottomNav = styled(Flex)`
    position: fixed;
    bottom: 0px;
    width: 100%;
    background: rgba(255, 255, 255, 0.20);
    background-blend-mode: overlay;
    box-shadow: 0px -5px 30px 0px rgba(41, 39, 130, 0.10), 0px 1px 0.5px 0px rgba(255, 255, 255, 0.50) inset;
    backdrop-filter: blur(40px);
    padding-bottom: env(safe-area-inset-bottom);
    html[data-useragent*="TokenPocket_iOS"] & {
        padding-bottom: 45px;
    }
    z-index: 20;
`;

export default StyledBottomNav;
