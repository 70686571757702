import { useTranslation, languageList } from '@pancakeswap/localization'
import { Menu as UikitMenu} from '@pancakeswap/uikit'
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import UserMenu from './UserMenu'
import { footerLinks } from './config/footerConfig'
import { useMenuItems } from './hooks/useMenuItems'
import { getActiveMenuItem, getActiveSubMenuItem, getParentMenu } from './utils'

const Menu = (props) => {
    const { account } = useActiveWeb3React();
    const { currentLanguage, setLanguage, t } = useTranslation()
    const { pathname } = useRouter()
    const url = getParentMenu(pathname)
    const menuItems = useMenuItems()

    const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, url })
    const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, url })
    console.log("currentLanguage.code", currentLanguage.code)
    const getFooterLinks = useMemo(() => {
        return footerLinks(t)
    }, [t]);

    return (
        <>
            <UikitMenu
                linkComponent={(linkProps) => {
                    return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
                }}
                rightSide={
                    <div className="flex">
                        {account && <NetworkSwitcher />}
                        <div className="mr-0 lg:mr-2">
                            <UserMenu />
                        </div>
                    </div>
                }
                links={menuItems}
                footerLinks={getFooterLinks}
                activeItem={activeMenuItem?.href}
                activeSubItem={activeSubMenuItem?.href}
                currentLang="en"
                // langs={languageList}
                setLang={setLanguage}
                {...props}
            />
        </>
    )
}

export default Menu