import Image from 'next/future/image'
import { HelpIcon } from '@pancakeswap/uikit'
import { isChainSupported } from 'utils/wagmi'
import { memo } from 'react'

export const ChainLogo = memo(
    ({ chainId, width = 24, height = 24 }: { chainId: number; width?: number; height?: number }) => {
        if (isChainSupported(chainId)) {
            return (
                <Image
                    alt={`chain-${chainId}`}
                    style={{ maxHeight: `${height}px` }}
                    src={`/images/chains/${chainId}.png?version=2.0`}
                    width={width}
                    height={height}
                    unoptimized
                />
            )
        }

        return <HelpIcon width={width} height={height} />
    },
)
