import { ChainId, Token } from '@pancakeswap/sdk'

export const AribitrumTokens = {
    Arbi: new Token(
        ChainId.ARIBITRUMONE,
        '0xb8c77482e45f1f44de1745f52c74426c631bdd52',
        18,
        'ARBI',
        'ARBI',
        'https://www.binance.com/',
    ),
}
