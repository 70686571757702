import { Box, BoxProps } from '@pancakeswap/uikit'
import styled from 'styled-components'

const Container: React.FC<React.PropsWithChildren<BoxProps>> = ({ children, maxWidth = "1320px", ...props }) => (
    <CsBox px={['16px', '20px']} paddingBottom="1.5rem" mx="auto" maxWidth={maxWidth} {...props}>
        {children}
    </CsBox>
)

export default Container

const CsBox = styled(Box)`
    @media screen and (min-width: 821px) and (max-width: 1024px) {
        padding: 0px 20px 0px 20px !important;
    }
    @media screen and (max-width:600px) {
        padding:0px 12px;
    }
`
