import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
    return (
        <Svg viewBox="0 0 24 25" {...props} style={{ fill: "none" }}>
            <path d="M12 2.5C6.49 2.5 2 6.99 2 12.5C2 18.01 6.49 22.5 12 22.5C17.51 22.5 22 18.01 22 12.5C22 6.99 17.51 2.5 12 2.5ZM15.59 11.09L12.07 14.61C11.92 14.76 11.73 14.83 11.54 14.83C11.35 14.83 11.15 14.76 11.01 14.61L9.25 12.85C8.95 12.56 8.95 12.08 9.25 11.79C9.54 11.5 10.02 11.5 10.31 11.79L11.54 13.02L14.53 10.03C14.82 9.74 15.29 9.74 15.59 10.03C15.88 10.32 15.88 10.8 15.59 11.09Z" fill="#F74746"/>
        </Svg>
    );
};

export default Icon;
