import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 24 24" {...props}>
            <path d="M16.0246 10.2403H13.3861C12.8701 10.1702 11.586 8.57078 11.1639 8.04187C10.9763 7.8061 10.8062 7.59581 10.6596 7.42376L10.2785 6.98407L10.6596 6.54438C10.8062 6.3787 10.9763 6.16204 11.1639 5.92626C11.586 5.40373 12.876 3.80428 13.3744 3.72781H16.0246V4.98316L20 2.49158L16.0246 0V1.22986H13.3744C11.9965 1.22986 10.771 2.61265 9.42832 4.2822C9.25828 4.49249 9.10583 4.68366 8.97684 4.8366L8.70126 5.15521L8.42568 4.8366C8.29669 4.68366 8.13838 4.49249 7.96834 4.2822C6.63149 2.61265 5.40604 1.22986 4.02814 1.22986H1.14922C0.515978 1.22986 0 1.79062 0 2.47883C0 3.16705 0.515978 3.72781 1.14922 3.72781H4.01642C4.53239 3.79791 5.81648 5.39736 6.23864 5.92626C6.42627 6.16204 6.59631 6.37233 6.74289 6.54438L7.12401 6.98407L6.74289 7.42376C6.59631 7.59581 6.42627 7.8061 6.23864 8.04187C5.81648 8.57078 4.52653 10.1639 4.02814 10.2403H1.14922C0.515978 10.2403 0 10.8011 0 11.4893C0 12.1775 0.515978 12.7383 1.14922 12.7383H4.02814C5.40604 12.7383 6.62562 11.3555 7.9742 9.68594C8.14424 9.47565 8.29669 9.28448 8.43154 9.13154L8.70712 8.81293L8.9827 9.13154C9.1117 9.28448 9.27001 9.47565 9.43418 9.68594C10.7769 11.3555 12.0023 12.7383 13.3802 12.7383H16.0305V13.9936L20.0059 11.502L16.0305 9.01047V10.2403H16.0246Z" fill="#B9BCBE" />
        </Svg>
    );
};

export default Icon;
