import styled from "styled-components";
import { StyledMenuItemProps } from "./types";

export const StyledMenuItemContainer = styled.div<StyledMenuItemProps>`
    position: relative;
    ${({ $isActive, $variant, theme }) =>
        $isActive &&
        $variant === "subMenu" &&
        `
      &:after{
        content: "";
        position: absolute;
        bottom: 0;
        height: 4px;
        width: 100%;
        background-color: ${theme.colors.primary};
        border-radius: 2px 2px 0 0;
      }
    `};
`;

const StyledMenuItem = styled.a<StyledMenuItemProps>`
    position: relative;
    display: flex;
    align-items: center;
    color: ${({ theme, $isActive }) => ($isActive ? theme.colors.primaryBright : theme.colors.textMenu)};
    font-size: 14px;
    font-weight: ${({ $isActive }) => ($isActive ? "600" : "600")};
    opacity: ${({ $isDisabled }) => ($isDisabled ? 0.5 : 1)};

    ${({ $statusColor, theme }) =>
        $statusColor &&
        `
    &:after {
      content: "";
      border-radius: 100%;
      background: ${theme.colors[$statusColor]};
      height: 8px;
      width: 8px;
      margin-left: 12px;
    }
  `}

    ${({ $variant }) =>
        $variant === "default"
            ? `
    padding: 0 16px;
    height: 48px;
  `
            : `
    padding: 4px 4px 0px 4px;
    height: 42px;
  `}
  > svg {
        fill: ${({ theme, $isActive }) => ($isActive ? theme.colors.primaryBright : theme.colors.text)};
    }
    &:hover {
        background: transparent;
        color: ${({ theme }) => theme.colors.primaryBright};
        cursor: pointer;
        ${({ $variant }) => $variant === "default" && "border-radius: 16px;"};
        > svg {
            fill: ${({ theme }) => theme.colors.primaryBright};
        }
    }
`;

export default StyledMenuItem;
