import styled from "styled-components";
import Button from "../Button/Button";

const MenuButton = styled(Button)`
    color: ${({ theme }) => theme.colors.text};
    padding: 0 6px;
    border-radius: 0px !important;
    display: flex;
    justify-content: start;
    gap:8px;
    align-items: center;
    > img {
        width: 28px;
        height: 20px;
        margin: 8px;
        border-radius:0px !important;
    }
`;
MenuButton.defaultProps = {
    variant: "text",
    size: "sm",
};

export default MenuButton;
