import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { actionDataListAllowanceFromToken, actionDataListAllowanceToToken, actionGetAllowanceToken, getDataCrossChain } from "./actions";
import { ListAllowanceTokenType, OptionListAllowanceTokenType } from './type';

interface InitialStateType {
    storeFromTokenSupport: OptionListAllowanceTokenType[],
    storeToTokenSupport: OptionListAllowanceTokenType[],
    storeAllowanceToken: OptionListAllowanceTokenType,
    data: any
}

const initialState: InitialStateType = {
    storeFromTokenSupport: [],
    storeToTokenSupport: [],
    storeAllowanceToken: {} as OptionListAllowanceTokenType,
    data: null
};

export default createReducer(initialState, (builder) =>
    builder
        .addCase(actionDataListAllowanceFromToken, (state: InitialStateType, action: PayloadAction<ListAllowanceTokenType>) => {
            state.storeFromTokenSupport = action.payload.listAllowanceToken;
        })
        .addCase(actionDataListAllowanceToToken, (state: InitialStateType, action: PayloadAction<ListAllowanceTokenType>) => {
            state.storeToTokenSupport = action.payload.listAllowanceToken;
        })
        .addCase(actionGetAllowanceToken, (state: InitialStateType, action: PayloadAction<{ address: string }>) => {
            const { address } = action.payload;
            const { storeFromTokenSupport } = state;
            const getAllowanceToken = storeFromTokenSupport.find((item) => item.address.toLowerCase() === address.toLowerCase());
            state.storeAllowanceToken = getAllowanceToken;
        })
        .addCase(getDataCrossChain, (state, action) => {
            state.data = action.payload.data
        })
);