import { createReducer } from '@reduxjs/toolkit'
import {
    getLoading,
    getDataListHisroty,
    getDataListSummary
} from "./actions"
import { ItemHistory, ItemSummary } from './type'


interface global {
    isLoading: boolean
    listHistory: ItemHistory[]
    listSummary: ItemSummary
    // user: string,
    // totalTx: string,
    // total: string

}
export const initialState: global = {
    isLoading: false,
    listHistory: [],
    listSummary: {
        user: "",
        totalTx: "",
        total: ""
    }
    // user: "",
    // totalTx: "",
    // total: ""
}
export default createReducer(initialState, (builder) =>
    builder
        .addCase(getLoading, (state, action) => {
            state.isLoading = action.payload.isLoading
        })
        .addCase(getDataListHisroty, (state, action) => {
            state.listHistory = action.payload.listHistory
        })
        .addCase(getDataListSummary, (state, action) => {
            // state.total = action.payload.total
            // state.totalTx = action.payload.totalTx
            // state.user = action.payload.user
            state.listSummary.total = action.payload.total
            state.listSummary.totalTx = action.payload.totalTx
            state.listSummary.user = action.payload.user
        })
)