import { ChainId, Token, WETH9 } from '@pancakeswap/sdk'
import { BUSD_TESTNET, CAKE_TESTNET } from './common'

export const polygonTokens = {
    weth: WETH9[ChainId.POLYGON],
    usdt: new Token(
        ChainId.POLYGON,
        '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
        6,
        'USDT',
        'USDT',
        'https://mapper.polygon.technology/',
    ),
    bnb: new Token(
        ChainId.POLYGON,
        '0x3BA4c387f786bFEE076A58914F5Bd38d668B42c3',
        18,
        'BNB',
        'BNB',
        'https://www.binance.com/',
    ),
    usdc: new Token(
        ChainId.POLYGON,
        '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        18,
        'USDC',
        'uSDC',
        'https://www.centre.io/',
    ),
    shib: new Token(
        ChainId.POLYGON,
        '0x6f8a06447Ff6FcF75d803135a7de15CE88C1d4ec',
        18,
        'SHIB',
        'SHIB',
        'https://shibatoken.com/',
    ),
    vndc2: new Token(
        ChainId.POLYGON,
        '0xd0076D7E57AAC42ec3F9d6E7C604d04A5b7030Fa',
        0,
        'VNDC',
        'VNDC',
        '',
    ),
}
