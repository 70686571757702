import React from "react";
import styled, { DefaultTheme } from "styled-components";
import CheckmarkCircleIcon from "../Svg/Icons/CheckmarkCircle";
import ErrorIcon from "../Svg/Icons/Error";
import BlockIcon from "../Svg/Icons/Block";
import InfoIcon from "../Svg/Icons/Info";
import { Text } from "../Text";
import { IconButton } from "../Button";
import { CloseIcon } from "../Svg";
import Flex from "../Box/Flex";
import { AlertProps, variants } from "./types";

interface ThemedIconLabel {
    variant: AlertProps["variant"];
    theme: DefaultTheme;
    hasDescription: boolean;
}

const getThemeColor = ({ theme, variant = variants.INFO }: ThemedIconLabel) => {
    switch (variant) {
        case variants.DANGER:
            return theme.colors.failure;
        case variants.WARNING:
            return theme.colors.warning;
        case variants.SUCCESS:
            return theme.colors.success;
        case variants.INFO:
        default:
            return theme.colors.secondary;
    }
};

const getIcon = (variant: AlertProps["variant"] = variants.INFO) => {
    switch (variant) {
        case variants.DANGER:
            return BlockIcon;
        case variants.WARNING:
            return ErrorIcon;
        case variants.SUCCESS:
            return CheckmarkCircleIcon;
        case variants.INFO:
        default:
            return InfoIcon;
    }
};

const IconLabel = styled.div<ThemedIconLabel>`
    /* background-color: ${getThemeColor}; */
    border-radius: 5px 0 0 5px;
    color: ${({ theme }) => theme.alert.background};
    padding: 12px;
    display: flex;
    align-items: center;
`;

const withHandlerSpacing = 32 + 12 + 8; // button size + inner spacing + handler position
const Details = styled.div<{ hasHandler: boolean }>`
    flex: 1;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: ${({ hasHandler }) => (hasHandler ? `${withHandlerSpacing}px` : "12px")};
    padding-top: 12px;
`;

const CloseHandler = styled.div`
    border-radius: 0 16px 16px 0;
    right: 8px;
    position: absolute;
    top: 8px;
`;

const StyledAlert = styled(Flex)`
    position: relative;
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: 6px;
    box-shadow: -2px 2px 8px ${({ theme }) => (theme.isDark ? "rgba(255,255,255,0.2)" : "rgba(0, 0, 0, 0.25)")};
    flex-direction:column;
    overflow:hidden;
`;
const ContainerIcon = styled(Flex)`
    padding-right: 12px;
    border-right: 2px solid ${({ theme }) => theme.colors.cardBorder};
`
const BorderBottomSUCCESS = styled(Flex)`
    border-bottom: 3px solid ${({ theme }) => theme.colors.success};
    border-radius: 12px;
    width: 100px;
`

const BorderBottomERROR = styled(Flex)`
    border-bottom: 3px solid ${({ theme }) => theme.colors.warning43};
    border-radius: 12px;
    width: 100px;
`
const getBorderbottom = (variant: AlertProps["variant"] = variants.SUCCESS) => {
    switch (variant) {
        case variants.DANGER:
            return BorderBottomERROR;
        default:
            return BorderBottomSUCCESS;
    }
};
const Alert: React.FC<React.PropsWithChildren<AlertProps>> = ({ title, children, variant, onClick }) => {
    const Icon = getIcon(variant);
    const Border = getBorderbottom(variant)
    return (
        <StyledAlert>
            <Flex>
                <IconLabel variant={variant} hasDescription={!!children}>
                    <ContainerIcon>
                        <Icon color="currentColor" width="24px" />
                    </ContainerIcon>
                </IconLabel>
                <Details hasHandler={!!onClick}>
                    <Text bold>{title}</Text>
                    {typeof children === "string" ? <Text as="p">{children}</Text> : children}
                </Details>
                {onClick && (
                    <CloseHandler>
                        <IconButton scale="sm" variant="text" onClick={onClick}>
                            <CloseIcon width="24px" color="currentColor" />
                        </IconButton>
                    </CloseHandler>
                )}
            </Flex>
            <Border/>
        </StyledAlert>
    );
};

export default Alert;
