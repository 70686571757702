import BigNumber from 'bignumber.js'
import { BIG_ONE, BIG_ZERO } from 'utils/bigNumber'
import { filterFarmsByQuoteToken } from '@pancakeswap/farms'
import { SerializedFarm } from 'state/types'

const getFarmFromTokenSymbol = (
    farms: SerializedFarm[],
    tokenSymbol: string,
    preferredQuoteTokens?: string[],
): SerializedFarm => {
    const farmsWithTokenSymbol = farms.filter((farm) => farm.token.symbol === tokenSymbol)
    const filteredFarm = filterFarmsByQuoteToken(farmsWithTokenSymbol, preferredQuoteTokens)
    return filteredFarm
}

const getFarmBaseTokenPrice = (
    farm: SerializedFarm,
    quoteTokenFarm: SerializedFarm,
    nativePriceUSD: BigNumber,
    wNative: string,
    stable: string,
): BigNumber => {
    const hasTokenPriceVsQuote = Boolean(farm.tokenPriceVsQuote)

    if (farm.quoteToken.symbol === stable) {
        return hasTokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : BIG_ZERO
    }

    if (farm.quoteToken.symbol === wNative) {
        return hasTokenPriceVsQuote ? nativePriceUSD.times(farm.tokenPriceVsQuote) : BIG_ZERO
    }

    // We can only calculate profits without a quoteTokenFarm for BUSD/BNB farms
    if (!quoteTokenFarm) {
        return BIG_ZERO
    }

    // Possible alternative farm quoteTokens:
    // UST (i.e. MIR-UST), pBTC (i.e. PNT-pBTC), BTCB (i.e. bBADGER-BTCB), ETH (i.e. SUSHI-ETH)
    // If the farm's quote token isn't BUSD or WBNB, we then use the quote token, of the original farm's quote token
    // i.e. for farm PNT - pBTC we use the pBTC farm's quote token - BNB, (pBTC - BNB)
    // from the BNB - pBTC price, we can calculate the PNT - BUSD price
    if (quoteTokenFarm.quoteToken.symbol === wNative) {
        const quoteTokenInBusd = nativePriceUSD.times(quoteTokenFarm.tokenPriceVsQuote)
        return hasTokenPriceVsQuote && quoteTokenInBusd
            ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInBusd)
            : BIG_ZERO
    }

    if (quoteTokenFarm.quoteToken.symbol === stable) {
        const quoteTokenInBusd = quoteTokenFarm.tokenPriceVsQuote
        return hasTokenPriceVsQuote && quoteTokenInBusd
            ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInBusd)
            : BIG_ZERO
    }

    // Catch in case token does not have immediate or once-removed BUSD/WBNB quoteToken
    return BIG_ZERO
}

const getFarmQuoteTokenPrice = (
    farm: SerializedFarm,
    quoteTokenFarm: SerializedFarm,
    nativePriceUSD: BigNumber,
    wNative: string,
    stable: string,
): BigNumber => {
    if (farm.quoteToken.symbol === stable) {
        return BIG_ONE
    }

    if (farm.quoteToken.symbol === wNative) {
        return nativePriceUSD
    }

    if (!quoteTokenFarm) {
        return BIG_ZERO
    }

    if (quoteTokenFarm.quoteToken.symbol === wNative) {
        return quoteTokenFarm.tokenPriceVsQuote ? nativePriceUSD.times(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
    }

    if (quoteTokenFarm.quoteToken.symbol === stable) {
        return quoteTokenFarm.tokenPriceVsQuote ? new BigNumber(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
    }

    return BIG_ZERO
}

const getFarmsPrices = (farms: SerializedFarm[], chainId: number) => {
    if (!nativeStableLpMap[chainId]) {
        throw new Error(`chainId ${chainId} not supported`)
    }

    const nativeStableFarm = farms.find(
        (farm) => farm.lpAddress.toLowerCase() === nativeStableLpMap[chainId].address.toLowerCase(),
    )
    const nativePriceUSD = nativeStableFarm.tokenPriceVsQuote
        ? BIG_ONE.div(nativeStableFarm.tokenPriceVsQuote)
        : BIG_ZERO
    const farmsWithPrices = farms.map((farm) => {
        const quoteTokenFarm = getFarmFromTokenSymbol(farms, farm.quoteToken.symbol, [
            nativeStableLpMap[chainId].wNative,
            nativeStableLpMap[chainId].stable,
        ])
        const tokenPriceBusd = getFarmBaseTokenPrice(
            farm,
            quoteTokenFarm,
            nativePriceUSD,
            nativeStableLpMap[chainId].wNative,
            nativeStableLpMap[chainId].stable,
        )
        const quoteTokenPriceBusd = getFarmQuoteTokenPrice(
            farm,
            quoteTokenFarm,
            nativePriceUSD,
            nativeStableLpMap[chainId].wNative,
            nativeStableLpMap[chainId].stable,
        )

        return {
            ...farm,
            tokenPriceBusd: tokenPriceBusd.toJSON(),
            quoteTokenPriceBusd: quoteTokenPriceBusd.toJSON(),
        }
    })

    return farmsWithPrices
}

export default getFarmsPrices

const nativeStableLpMap = {
    5: {
        address: '0xf5bf0C34d3c428A74Ceb98d27d38d0036C587200',
        wNative: 'WETH',
        stable: 'USDC',
    },
    56: {
        address: '0xc643E83587818202E0fFf5eD96D10Abbc8Bb48e7',
        wNative: 'WBNB',
        stable: 'BUSD',
    },
    97: {
        address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
        wNative: 'WBNB',
        stable: 'BUSD',
    },
    1945: {
        address: '0x6fd04d2f42c5AB3635220f6ecf54825e084bf870',
        wNative: 'WONUS',
        stable: 'BUSD',
    },
    1975: {
        address: '0x9e3d30d7808C8E64dB360Abf2f32B44eB03F55d4',
        wNative: 'WONUS',
        stable: 'VNDC',
    },
}
