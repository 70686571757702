import React from "react";
import styled from "styled-components";
import { useTranslation } from "@pancakeswap/localization";
import Text from "../Text/Text";
import Dropdown from "../Dropdown/Dropdown";
import Button from "../Button/Button";
import LanguageIcon from "../Svg/Icons/Language";
import MenuButton from "./MenuButton";
import { Colors } from "../../theme";
import { Language } from "./types";
import { Position } from "../Dropdown/types";
import { Scale } from "../Button/types";
import { ChevronDownIcon } from "../Svg";

const ContainerLanguage = styled.div`
    width: auto;
    height: 36px !important;
    border: 1px solid ${({ theme }) => theme.colors.cardBorder};
    font-weight:600;
    border-radius: 12px;
    padding-left:14px;
    padding-right:14px;
    padding-top:10px;
    padding-bottom:10px;
    display: flex;
    justify-content: center;
    gap:6px;
    align-items: center;
    > img {
       width:20px;
       height:20px;
        border-radius: 100%;
        // overflow: hidden;
        object-fit: cover;
    }
    ${Text}{
        font-size: 14px;
        padding-left: 2px;
        padding-right: 2px;
    }
    margin-left: 12px;
    @media screen and (max-width:600px) {
        padding-top:8px;
        padding-bottom:8px;
        border-radius: 8px;
        > img {
            width:16px;
            height:16px;
            border-radius: 100%;
            // overflow: hidden;
            object-fit: cover;
        }
        ${Text}{
            padding-left: 2px;
            padding-right: 2px;
            font-size: 10px;
        }
    }
`
interface Props {
    currentLang: string;
    langs: Language[];
    setLang: (lang: Language) => void;
    color: keyof Colors;
    dropdownPosition?: Position;
    buttonScale?: Scale;
    hideLanguage?: boolean;
}

const LangSelector: React.FC<React.PropsWithChildren<Props>> = ({
    currentLang,
    langs,
    color,
    setLang,
    dropdownPosition = "bottom",
    buttonScale = "md",
    hideLanguage = false,
}) => {
    const { t } = useTranslation()
    return (
        <Dropdown
            position={dropdownPosition}
            target={
                // <Button scale={buttonScale}>
                //     <Text color={color}>{currentLang?.toUpperCase()}</Text>
                // </Button>
                <ContainerLanguage>
                    <img src={`/images/language/${currentLang?.toLowerCase()}-language.png`} alt="language code" />
                    <Text fontSize="10px" fontWeight={600} color="text">{currentLang?.toUpperCase()}</Text>
                    <ChevronDownIcon color="textDisable" />
                </ContainerLanguage>
            }
        >
            {langs.map((lang) => (
                <MenuButton
                    key={lang.locale}
                    fullWidth
                    onClick={() => setLang(lang)}
                    // Safari fix
                    style={{ minHeight: "32px", height: "auto" }}
                >
                    <img src={`/images/language/${lang?.code.toLowerCase()}-language.png`} alt="language code" />
                    {/* <Text color="text">{currentLang?.toUpperCase()}</Text> */}

                    {currentLang?.toLowerCase() === lang.code.toLowerCase() ? 
                        <Text fontSize="14px" color="textDisable">{t(lang.language.toString())}</Text>
                     : 
                        <Text fontSize="14px" bold color="text">{t(lang.language.toString())}</Text>
                    }
                </MenuButton>
            ))}
        </Dropdown>
    )
}

export default React.memo(LangSelector, (prev, next) => prev.currentLang === next.currentLang);

