import axios from 'axios'

// Add a request interceptor
export default function configAxios() {
    const secret = process.env.NEXT_PUBLIC_API_KEY
    axios.defaults.baseURL = process.env.NEXT_PUBLIC_API
    axios.defaults.headers["content-type"] = "application/json";
    axios.defaults.timeout = 10000;
    axios.defaults.params = { apikey: secret };
    axios.interceptors.request.use(
      async (config) => {
          return config
      },
      (error) => {
          Promise.reject(error)
      },
  )

}
