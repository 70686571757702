import { useTranslation } from '@pancakeswap/localization'
import { Box, Flex, LogoutIcon, Text, UserMenu as UIKitUserMenu, UserMenuItem, UserMenuVariant } from '@pancakeswap/uikit'
import BigNumber from 'bignumber.js'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Trans from 'components/Trans'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useAuth from 'hooks/useAuth'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { useEffect, useState } from 'react'
import { usePendingTransactions } from 'state/transactions/hooks'
import styled from 'styled-components'
import { formatBigNumber } from 'utils/formatBalance'
import { useAccount, useBalance } from 'wagmi'

const UserMenu = () => {
    const { t } = useTranslation()
    const { address: account } = useAccount()
    const { isWrongNetwork } = useActiveChainId()

    const native = useNativeCurrency()
    const nativeBalance = useBalance({ address: account, enabled: !false })

    const { logout } = useAuth()
    const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
    const [userMenuText, setUserMenuText] = useState<string>('')
    const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')
    const avatarSrc = undefined
    useEffect(() => {
        if (hasPendingTransactions) {
            setUserMenuText(t('%num% Pending', { num: pendingNumber }))
            setUserMenuVariable('pending')
        } else {
            setUserMenuText('')
            setUserMenuVariable('default')
        }
    }, [hasPendingTransactions, pendingNumber, t])


    const UserMenuItems = () => {
        return (
            <>
                <UserMenuItem as="button" onClick={logout}>
                    <Flex alignItems="center" justifyContent="space-between" width="100%">
                        {t('Disconnect')}
                        <LogoutIcon />
                    </Flex>
                </UserMenuItem>
            </>
        )
    }

    if (account) {
        return (
            <UIKitUserMenu
                acc={account}
                avatarSrc={avatarSrc}
                text={userMenuText}
                variant={userMenuVariable}
                isShowIcon={false}
                symbolNative={native?.symbol}
                nativeBalance={(!nativeBalance?.isFetched || nativeBalance.data === undefined) ? "0" : formatBigNumber(nativeBalance.data.value, 4)}
            >
                {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
            </UIKitUserMenu>
        )
    }

    if (isWrongNetwork) {
        return (
            <UIKitUserMenu text={t('Network')} variant="danger">
                {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
            </UIKitUserMenu>
        )
    }

    return (
        <CsConnectWalletButton scale="sm">

            <Text bold color="#fff" fontSize={['10px', , , '14px']}>{t("Connect Wallet")}</Text>

        </CsConnectWalletButton>
    )
}

export default UserMenu

const CsConnectWalletButton = styled(ConnectWalletButton)`
    height: 40px !important;
    width:164px;
    padding-left:0px;
    padding-right:0px;
    background: ${({ theme }) => theme.colors.primaryBright};;
    color: ${({ theme }) => theme.colors.backgroundAlt};
    border: 2px solid ${({ theme }) => theme.colors.backgroundAlt};
    @media screen and (max-width:600px){
        width: 102px;
        height: 28px;
        border-radius:8px;
    }
`
