import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 24 24" {...props}>
            <path d="M18.97 13.08C18.73 15.75 16.8 17.5 14 17.5H5C2.24 17.5 0 15.26 0 12.5V5.5C0 2.78 1.64 0.88 4.19 0.56C4.45 0.52 4.72 0.5 5 0.5H14C14.26 0.5 14.51 0.51 14.75 0.55C17.14 0.83 18.76 2.5 18.97 4.92C19 5.21 18.76 5.45 18.47 5.45H16.92C15.96 5.45 15.07 5.82 14.43 6.48C13.67 7.22 13.29 8.26 13.38 9.3C13.54 11.12 15.14 12.55 17.04 12.55H18.47C18.76 12.55 19 12.79 18.97 13.08Z" fill="#B9BCBE" />
        </Svg>
    );
};

export default Icon;
