import { ChainId, Token, WETH9 } from '@pancakeswap/sdk'

export const mumbaiTokens = {
    weth: WETH9[ChainId.MUMBAI],
    usdc: new Token(
        ChainId.MUMBAI,
        '0xFCe7187B24FCDc9feFfE428Ec9977240C6F7006D',
        6,
        'USDC',
        'uSDC Token',
        'https://www.centre.io/',
    ),
    usdt: new Token(
        ChainId.MUMBAI,
        '0xFCe7187B24FCDc9feFfE428Ec9977240C6F7006D',
        6,
        'USDC',
        'uSDC Token',
        'https://www.centre.io/',
    ),
}
