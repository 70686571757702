import { ContextApi } from '@pancakeswap/localization'
import {
    ContactFillIcon,
    ContactIcon,
    CrossChains,
    CrossChainsFillIcon,
    HistoryIcon,
    HistoryFillIcon,
    MenuItemsType
} from '@pancakeswap/uikit'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
    items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
    if (!chainId || !item.supportChainIds) {
        return item
    }
    if (item.supportChainIds?.includes(chainId)) {
        return item
    }
    return {
        ...item,
        disabled: true,
    }
}

const config: (
    t: ContextApi['t'],
    isDark: boolean,
    languageCode?: string,
    chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
        [
            {
                label: t('Crosschains'),
                icon: CrossChains,
                fillIcon: CrossChainsFillIcon,
                href: '/',
                showItemsOnMobile: false,
                items: [
                    {
                        label: t('Crosschains'),
                        href: '/',
                    },
                ].map((item) => addMenuItemSupported(item, chainId)),
            },
            {
                label: t('History'),
                icon: HistoryIcon,
                fillIcon: HistoryFillIcon,
                href: '/history',
                showItemsOnMobile: false,
                items: [
                    {
                        label: t('History'),
                        href: '/history',
                    },
                ].map((item) => addMenuItemSupported(item, chainId)),
            },
            {
                label: t('Contacts'),
                icon: ContactIcon,
                fillIcon: ContactFillIcon,
                href: '/contacts',
                showItemsOnMobile: false,
                items: [
                    {
                        label: t('Contacts'),
                        href: '/contacts',
                    },
                ].map((item) => addMenuItemSupported(item, chainId)),
            },
        ].map((item) => addMenuItemSupported(item, chainId))

export default config
