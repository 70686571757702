import { formatEther } from '@ethersproject/units'
import { MultiCallV2 } from '@pancakeswap/multicall'
import { ChainId } from '@pancakeswap/sdk'
import { masterChefAddresses } from './const'
import { FarmWithPrices, getFarmsPrices } from './farmPrices'
import { farmV2FetchFarms, FetchFarmsParams, fetchMasterChefV2Data } from './fetchFarms'

const supportedChainId = [
    ChainId.GOERLI,
    ChainId.BSC,
    ChainId.BSC_TESTNET,
    ChainId.ETHEREUM,
    ChainId.ONUS_TESTNET,
    ChainId.ONUS,
]
export const bCakeSupportedChainId = [ChainId.BSC, ChainId.BSC_TESTNET]

export function createFarmFetcher(multicallv2: MultiCallV2) {
    const fetchFarms = async (
        params: {
            isTestnet: boolean
        } & Pick<FetchFarmsParams, 'chainId' | 'farms'>,
    ) => {
        const { isTestnet, farms, chainId } = params
        // const masterChefAddress = isTestnet ? masterChefAddresses[ChainId.BSC_TESTNET] : masterChefAddresses[ChainId.BSC]
        const masterChefAddress = masterChefAddresses[chainId]

        const { poolLength, totalRegularAllocPoint, cakePerBlock } = await fetchMasterChefV2Data({
            isTestnet,
            multicallv2,
            masterChefAddress,
            chainId,
        })
        const regularCakePerBlock = formatEther(cakePerBlock)
        const farmsWithPrice = await farmV2FetchFarms({
            multicallv2,
            masterChefAddress,
            isTestnet,
            chainId,
            farms: farms.filter((f) => !f.pid || poolLength.gt(f.pid)),
            totalRegularAllocPoint,
        })

        return {
            farmsWithPrice,
            poolLength: poolLength.toNumber(),
            regularCakePerBlock: +regularCakePerBlock,
        }
    }
    return {
        fetchFarms,
        isChainSupported: (chainId: number) => supportedChainId.includes(chainId),
        supportedChainId,
        isTestnet: (chainId: number) =>
            ![ChainId.BSC, ChainId.ETHEREUM, ChainId.ONUS_TESTNET, ChainId.ONUS].includes(chainId),
    }
}

export * from './apr'
export * from './farmsPriceHelpers'
export * from './types'

export type { FarmWithPrices }

export { getFarmsPrices }
