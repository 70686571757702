import { useTranslation } from '@pancakeswap/localization'
import { ChainId, NATIVE } from '@pancakeswap/sdk'
import {
    ArrowDownIcon,
    ArrowUpIcon,
    Box,
    Button,
    Flex,
    ErrorFillIcon,
    Text,
    UserMenu,
    UserMenuDivider,
    UserMenuItem,
    useTooltip,
} from '@pancakeswap/uikit'
import { useWeb3React } from '@pancakeswap/wagmi'
import { useActiveChainId, useLocalNetworkChain } from 'hooks/useActiveChainId'
import { useNetworkConnectorUpdater } from 'hooks/useActiveWeb3React'
import { useHover } from 'hooks/useHover'
import { useSessionChainId } from 'hooks/useSessionChainId'
import { useSwitchNetwork } from 'hooks/useSwitchNetwork'
import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { chains } from 'utils/wagmi'
import { useNetwork } from 'wagmi'
import { ChainLogo } from './Logo/ChainLogo'

const NetworkSelect = ({ switchNetwork, chainId }) => {
    const { t } = useTranslation()
    const replaceChain = chains as any
    return (
        <>
            <Box px="16px" py="8px">
                <Text color="text">{t('Select a Network')}</Text>
            </Box>
            <UserMenuDivider />
            {replaceChain
                .filter((chain) => !chain?.testnet || chain.id === chainId)
                .map((chain) => (
                    <UserMenuItem
                        key={chain.id}
                        style={{ justifyContent: 'flex-start' }}
                        onClick={() => chain.id !== chainId && switchNetwork(chain.id)}
                    >
                        <ChainLogo chainId={chain.id} />
                        <Text fontSize="14px"
                            color={chain.id === chainId ? 'primaryBright' : 'text'}
                            bold={chain.id === chainId}
                            pl="12px"
                        >
                            {chain.name}
                        </Text>
                    </UserMenuItem>
                ))}
        </>
    )
}

const WrongNetworkSelect = ({ switchNetwork, chainId }) => {
    const { t } = useTranslation()
    const { targetRef, tooltip, tooltipVisible } = useTooltip(
        t(
            'The URL you are accessing (Chain id: %chainId%) belongs to %network%; mismatching your wallet’s network. Please switch the network to continue.',
            {
                chainId,
                network: chains.find((c) => c.id === chainId)?.name ?? 'Unknown network',
            },
        ),
        {
            placement: 'auto-start',
            hideTimeout: 0,
        },
    )
    const { chain } = useNetwork()
    const localChainId = useLocalNetworkChain() || ChainId.BSC
    const [, setSessionChainId] = useSessionChainId()

    const localChainName = chains.find((c) => c.id === localChainId)?.name ?? 'BSC'

    const [ref1, isHover] = useHover<HTMLButtonElement>()

    return (
        <>
            <Flex ref={targetRef} alignItems="center" px="16px" py="8px">
                <ErrorFillIcon color="warning43" />
                <Text color="text" pl="6px" bold>
                    {t('Please switch network')}
                </Text>
            </Flex>
            {tooltipVisible && tooltip}
            <UserMenuDivider />
            {chain && (
                <UserMenuItem
                    ref={ref1}
                    onClick={() => setSessionChainId(chain.id)}
                    style={{ justifyContent: 'flex-start' }}
                >
                    <ChainLogo chainId={chain.id} />
                    {/* <Text color="secondary" bold pl="12px">
                        {chain.name}
                    </Text> */}
                </UserMenuItem>
            )}
            <Box px="16px" pt="8px">
                {isHover ? <ArrowUpIcon color="text" /> : <ArrowDownIcon color="text" />}
            </Box>
            <UserMenuItem onClick={() => switchNetwork(localChainId)} style={{ justifyContent: 'flex-start' }}>
                <ChainLogo chainId={localChainId} />
                <Text pl="12px">{localChainName}</Text>
            </UserMenuItem>
            <Button my="8px" width="100%" onClick={() => switchNetwork(localChainId)}>
                {t('Switch network in wallet')}
            </Button>
        </>
    )
}

export const NetworkSwitcher = () => {
    const { t } = useTranslation()
    const { chainId, isWrongNetwork, isNotMatched } = useActiveChainId()
    const { pendingChainId, isLoading, canSwitch, switchNetworkAsync } = useSwitchNetwork()
    const router = useRouter()
    const { account } = useWeb3React()

    useNetworkConnectorUpdater()

    const foundChain = useMemo(
        () => chains.find((c) => c.id === (isLoading ? pendingChainId || chainId : chainId)),
        [isLoading, pendingChainId, chainId],
    )
    const symbol = NATIVE[foundChain?.id]?.symbol ?? foundChain?.nativeCurrency?.symbol
    const { targetRef, tooltip, tooltipVisible } = useTooltip(
        t('Unable to switch network. Please try it on your wallet'),
        { placement: 'auto' },
    )

    const cannotChangeNetwork = !canSwitch

    if (!chainId || (!account && router.pathname.includes('info'))) {
        return null
    }

    return (
        <Box ref={cannotChangeNetwork ? targetRef : null} height="100%">
            {cannotChangeNetwork && tooltipVisible && tooltip}
            <UserMenu
                mr="0px"
                placement="bottom"
                variant={isLoading ? 'pending' : isWrongNetwork ? 'danger' : 'default'}
                avatarSrc={`/images/chains/${chainId}.png?version=2.0`}
                disabled={cannotChangeNetwork}
                text={
                    isLoading ? (
                        t('Requesting')
                    ) : isWrongNetwork ? (
                        t('Network')
                    ) : foundChain ? (
                        <>
                            <Box display={['none', null, null, null, null, 'none']}>{foundChain.name}</Box>
                            <Box display={['none', null, null, null, null, 'none']}>{symbol}</Box>
                        </>
                    ) : (
                        t('Select a Network')
                    )
                }
            >
                {() =>
                    isNotMatched ? (
                        <WrongNetworkSelect switchNetwork={switchNetworkAsync} chainId={chainId} />
                    ) : (
                        <NetworkSelect switchNetwork={switchNetworkAsync} chainId={chainId} />
                    )
                }
            </UserMenu>
        </Box>
    )
}
