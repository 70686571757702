import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 24 24" {...props}>
            <path d="M16.5246 10.2403H13.8861C13.3701 10.1702 12.086 8.57078 11.6639 8.04187C11.4763 7.8061 11.3062 7.59581 11.1596 7.42376L10.7785 6.98407L11.1596 6.54438C11.3062 6.3787 11.4763 6.16204 11.6639 5.92626C12.086 5.40373 13.376 3.80428 13.8744 3.72781H16.5246V4.98316L20.5 2.49158L16.5246 0V1.22986H13.8744C12.4965 1.22986 11.271 2.61265 9.92832 4.2822C9.75828 4.49249 9.60583 4.68366 9.47684 4.8366L9.20126 5.15521L8.92568 4.8366C8.79669 4.68366 8.63838 4.49249 8.46834 4.2822C7.13149 2.61265 5.90604 1.22986 4.52814 1.22986H1.64922C1.01598 1.22986 0.5 1.79062 0.5 2.47883C0.5 3.16705 1.01598 3.72781 1.64922 3.72781H4.51642C5.03239 3.79791 6.31648 5.39736 6.73864 5.92626C6.92627 6.16204 7.09631 6.37233 7.24289 6.54438L7.62401 6.98407L7.24289 7.42376C7.09631 7.59581 6.92627 7.8061 6.73864 8.04187C6.31648 8.57078 5.02653 10.1639 4.52814 10.2403H1.64922C1.01598 10.2403 0.5 10.8011 0.5 11.4893C0.5 12.1775 1.01598 12.7383 1.64922 12.7383H4.52814C5.90604 12.7383 7.12562 11.3555 8.4742 9.68594C8.64424 9.47565 8.79669 9.28448 8.93154 9.13154L9.20712 8.81293L9.4827 9.13154C9.6117 9.28448 9.77001 9.47565 9.93418 9.68594C11.2769 11.3555 12.5023 12.7383 13.8802 12.7383H16.5305V13.9936L20.5059 11.502L16.5305 9.01047V10.2403H16.5246Z" fill="#E8A23A" />
        </Svg>
    );
};

export default Icon;
