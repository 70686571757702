import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 24 24" {...props}>
            <path d="M11.55 14.95L9.7 16.8C9.31 17.19 8.69 17.19 8.29 16.81C8.18 16.7 8.07 16.6 7.96 16.49C6.93 15.45 6 14.36 5.17 13.22C4.35 12.08 3.69 10.94 3.21 9.81C2.74 8.67 2.5 7.58 2.5 6.54C2.5 5.86 2.62 5.21 2.86 4.61C3.1 4 3.48 3.44 4.01 2.94C4.65 2.31 5.35 2 6.09 2C6.37 2 6.65 2.06 6.9 2.18C7.16 2.3 7.39 2.48 7.57 2.74L9.89 6.01C10.07 6.26 10.2 6.49 10.29 6.71C10.38 6.92 10.43 7.13 10.43 7.32C10.43 7.56 10.36 7.8 10.22 8.03C10.09 8.26 9.9 8.5 9.66 8.74L8.9 9.53C8.79 9.64 8.74 9.77 8.74 9.93C8.74 10.01 8.75 10.08 8.77 10.16C8.8 10.24 8.83 10.3 8.85 10.36C9.03 10.69 9.34 11.12 9.78 11.64C10.23 12.16 10.71 12.69 11.23 13.22C11.33 13.32 11.44 13.42 11.54 13.52C11.94 13.91 11.95 14.55 11.55 14.95Z" fill="#E8A23A" />
            <path d="M22.47 18.33C22.47 18.61 22.42 18.9 22.32 19.18C22.29 19.26 22.26 19.34 22.22 19.42C22.05 19.78 21.83 20.12 21.54 20.44C21.05 20.98 20.51 21.37 19.9 21.62C19.89 21.62 19.88 21.63 19.87 21.63C19.28 21.87 18.64 22 17.95 22C16.93 22 15.84 21.76 14.69 21.27C13.54 20.78 12.39 20.12 11.25 19.29C10.86 19 10.47 18.71 10.1 18.4L13.37 15.13C13.65 15.34 13.9 15.5 14.11 15.61C14.16 15.63 14.22 15.66 14.29 15.69C14.37 15.72 14.45 15.73 14.54 15.73C14.71 15.73 14.84 15.67 14.95 15.56L15.71 14.81C15.96 14.56 16.2 14.37 16.43 14.25C16.66 14.11 16.89 14.04 17.14 14.04C17.33 14.04 17.53 14.08 17.75 14.17C17.97 14.26 18.2 14.39 18.45 14.56L21.76 16.91C22.02 17.09 22.2 17.3 22.31 17.55C22.41 17.8 22.47 18.05 22.47 18.33Z" fill="#E8A23A" />
        </Svg>
    );
};

export default Icon;
