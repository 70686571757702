import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 24 24" {...props}>
            <path d="M19.47 13.08C19.23 15.75 17.3 17.5 14.5 17.5H5.5C2.74 17.5 0.5 15.26 0.5 12.5V5.5C0.5 2.78 2.14 0.88 4.69 0.56C4.95 0.52 5.22 0.5 5.5 0.5H14.5C14.76 0.5 15.01 0.51 15.25 0.55C17.64 0.83 19.26 2.5 19.47 4.92C19.5 5.21 19.26 5.45 18.97 5.45H17.42C16.46 5.45 15.57 5.82 14.93 6.48C14.17 7.22 13.79 8.26 13.88 9.3C14.04 11.12 15.64 12.55 17.54 12.55H18.97C19.26 12.55 19.5 12.79 19.47 13.08Z" fill="#E8A23A" />
        </Svg>
    );
};

export default Icon;
