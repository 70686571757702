import React, { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import styled from "styled-components";
import { useBalance } from 'wagmi'
import { Skeleton } from "../../../../components/Skeleton";
import { formatBigNumber } from "../../../../util/formatBalance";
import { Text } from "../../../../components/Text";
import { Box, Flex } from "../../../../components/Box";
import { ChevronDownIcon } from "../../../../components/Svg";
import { UserMenuProps, variants } from "./types";
import MenuIcon from "./MenuIcon";
import { UserMenuItem } from "./styles";

export const StyledUserMenu = styled(Flex)<{ isShow?: boolean }>`
    align-items: center;
    background-color: transparent;
    border-radius: 90px;
    cursor: pointer;
    display: inline-flex;
    height: 46px;
    padding-left: ${({ isShow }) => (isShow ? "32px" : "8px")};
    padding-right: 4px;
    position: relative;
    /* border: 2px solid ${({ theme }) => theme.colors.cardBorder}; */
    &:hover {
        opacity: 0.65;
    }
`;

export const LabelText = styled.div<{ isShowText?: boolean }>`
    color: ${({ theme }) => theme.colors.text};
    font-weight: 600;
    font-size: 14px;
    display: ${({ isShowText }) => (!isShowText ? "block" : "none")};
    @media screen and (max-width:600px) {
        font-size:12px;
    }
`;

export const LabelBalance = styled.div<{ isShowText?: boolean }>`
    color: ${({ theme }) => theme.colors.textSubtle};
    font-weight: normal;
    display: ${({ isShowText }) => (!isShowText ? "block" : "none")};
    ${Text}{
        font-size:14px;
    }
    @media screen and (max-width:600px) {
        ${Text}{
            font-size:10px;
        }
    }
`;


const Menu = styled.div<{ isOpen: boolean }>`
    background-color: ${({ theme }) => theme.card.background};
    border: 1px solid ${({ theme }) => theme.colors.cardBorder};
    border-radius: 16px;
    padding-bottom: 4px;
    padding-top: 4px;
    pointer-events: auto;
    width: 280px;
    padding: 12px;
    visibility: visible;
    z-index: 1001;

    ${({ isOpen }) =>
        !isOpen &&
        `
    pointer-events: none;
    visibility: hidden;
  `}
    ${UserMenuItem} {
        border-radius: 8px;
    }
`;

const UserMenu: React.FC<UserMenuProps> = ({
    acc,
    text,
    avatarSrc,
    variant = variants.DEFAULT,
    children,
    disabled,
    placement = "bottom-end",
    isShowIcon = !false,
    symbolNative,
    nativeBalance,
    ...props
}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null);
    const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);
    const [accountEllipsis, setAccountEllipsis] = useState<string | null>(null);
    useEffect(() => {
        if (window.screen.width < 321) {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const accountEllipsis = acc
                ? `${acc.substring(0, 2)}...${acc.substring(acc.length - 2)}`
                : null;
            setAccountEllipsis(accountEllipsis);
        }
        if (window.screen.width > 322) {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const accountEllipsis = acc
                ? `${acc.substring(0, 4)}...${acc.substring(acc.length - 4)}`
                : null;
            setAccountEllipsis(accountEllipsis);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.screen.width, acc]);
    const { styles, attributes } = usePopper(targetRef, tooltipRef, {
        strategy: "fixed",
        placement,
        modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
    });

    useEffect(() => {
        const showDropdownMenu = () => {
            setIsOpen(true);
        };

        const hideDropdownMenu = (evt: MouseEvent | TouchEvent) => {
            const target = evt.target as Node;
            if (target && !tooltipRef?.contains(target)) {
                setIsOpen(false);
                evt.stopPropagation();
            }
        };

        targetRef?.addEventListener("mouseenter", showDropdownMenu);
        targetRef?.addEventListener("mouseleave", hideDropdownMenu);

        return () => {
            targetRef?.removeEventListener("mouseenter", showDropdownMenu);
            targetRef?.removeEventListener("mouseleave", hideDropdownMenu);
        };
    }, [targetRef, tooltipRef, setIsOpen]);

    return (
        <Flex alignItems="center" width="100%" height="100%" ref={setTargetRef} {...props}>
            <StyledUserMenu
                onTouchStart={() => {
                    setIsOpen((s) => !s);
                }}
                isShow={isShowIcon}
            >
                {isShowIcon && <MenuIcon avatarSrc={avatarSrc} variant={variant} />}
                <Flex flexDirection="column" >
                    <LabelText isShowText={isShowIcon} title={typeof text === "string" ? text || acc : acc}>
                        {text || accountEllipsis}
                    </LabelText>
                    <LabelBalance isShowText={isShowIcon} title={typeof text === "string" ? text || acc : acc}>
                        <Text>{nativeBalance} {symbolNative}</Text>
                    </LabelBalance>
                </Flex>
                {/* {!disabled  && <ChevronDownIcon color="text" width="24px" />} */}
            </StyledUserMenu>
            {!disabled && (
                <Menu style={styles.popper} ref={setTooltipRef} {...attributes.popper} isOpen={isOpen}>
                    <Box onClick={() => setIsOpen(false)}>{children?.({ isOpen })}</Box>
                </Menu>
            )}
        </Flex>
    );
};

export default UserMenu;



