import { PancakeTheme } from '@pancakeswap/uikit'
import { createGlobalStyle } from 'styled-components'

declare module 'styled-components' {
    /* eslint-disable @typescript-eslint/no-empty-interface */
    export interface DefaultTheme extends PancakeTheme { }
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: Poppins,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0.025em;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.input};
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${({ theme }) => theme.colors.input};
    border-radius: 10px;
  }

  .MuiPickersPopper-root{
    .MuiPickersDay-root{
      &:hover{
          background: ${({ theme }) => theme.colors.primaryBright};
          color: ${({ theme }) => theme.colors.white};
        }
    }
    .Mui-selected{
      background: ${({ theme }) => theme.colors.primaryBright} !important;
    }
    .MuiPickersArrowSwitcher-root{
      .MuiPickersArrowSwitcher-button{
        &:hover{
          svg{
            path{
              fill: ${({ theme }) => theme.colors.primaryBright} !important;
            }
          }
        }
      }
    }
    .MuiPaper-rounded{
      border-radius: 16px;
      border: 1px solid #E3E3E8;
    }
    .MuiPickersCalendarHeader-label{
      font-weight: 700;
    }
  }
    .ant-picker-footer{
     padding-top: 4px;
     padding-bottom: 4px;
    .ant-picker-today-btn{
            color: #E8A23A !important;
            font-weight: 600;
        }
    }
    .ant-picker-cell-inner::before{
        border: 1px solid #E8A23A !important;
        border-radius: 8px !important;
    }
    .ant-picker-cell-selected{
        .ant-picker-cell-inner{
            background: #E8A23A !important;
            color: #fff !important;
            border-radius: 8px !important;
        }

    }

`

export default GlobalStyle
